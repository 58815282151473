@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    a {
        @apply  text-blue-600  ;
    }

    ul {
        @apply list-disc pl-[30px];
    }

    ol {
        @apply list-decimal  pl-[30px];
    }
}

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
