
.text-primary {
  color: #1D337A;
}
.text-primary2 {
  color: #27386C;
}
.text-secondary {
  color: #0F90D1;
}

.bg-primary{
  background-color: #1D337A;
}

.bg-primary2{
  background-color: #27386C;
}


.bg-blue-pattern {
  background : url("./assets/ellipse/top_left.png"), url("./assets/ellipse/bottom_left.png"), url("./assets/ellipse/top_right.png"), url("./assets/ellipse/bottom_right.png"), #27386C;
  background-position: -400px -400px, -100px 100px, center top -500px, right -100px top -200px ;
  background-repeat: no-repeat;
}

.bg-blue-pattern2 {
  background : url("./assets/ellipse/bottom_left.png"), url("./assets/ellipse/bottom_left.png"), url("./assets/ellipse/bottom_left.png"), url("./assets/ellipse/bottom_left.png"), #27386C;
  background-position: -100px -150px, 200px -50px, center top -500px, right -100px top 100px ;
  background-repeat: no-repeat;
}

/* syndicate by bana */
.bg-blue-pattern3 {
  background :  url("./assets/bg_patterns/syndicate.png"), #1D337A;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* maillist home page */
.bg-blue-mailList {
  background :  url("./assets/bg_patterns/maillist.png"), #0F90D1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-blue-applynow {
  background :  url("./assets/bg_patterns/apply.png"), #0F90D1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-blue-factory {
  background :  url("./assets/bg_patterns/factory.png"), #0F90D1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-blue-vision {
  background :  url("./assets/bg_patterns/vision.png"), #1D337A;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* maillist home page */
.bg-blue-footer {
  background :  url("./assets/bg_patterns/footer.png"), #1D337A;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-pattern-secondary{
  background : url("./assets//ellipse/top_left_1.png"), url("./assets//ellipse/top_right_1.png"), url("./assets//ellipse/bottom_right_1.png"), #0F90D1;
  background-position: -80px -250px, right 0 top -350px, right;
  background-repeat: no-repeat;
}


@media screen and (max-width : 450px) {
  .bg-blue-pattern3{
    background :  url("./assets/bg_patterns/syndicate-mob.png"), #1D337A;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: right; */
    /* background-size: contain; */
  }

  .bg-blue-mailList {
    background :  url("./assets/bg_patterns/maillist-mob.png"), #0F90D1;
    /* background-position: center; */
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-blue-vision {
    background :  url("./assets/bg_patterns/vision-mob.png"), #1D337A;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-blue-applynow {
    background :  url("./assets/bg_patterns/apply-mob.png"), #0F90D1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-blue-factory {
    background :  url("./assets/bg_patterns/factory-mob.png"), #0F90D1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

}


/* .swiper-slide-next .center-card-testimonails{ */


.center-card-testimonails  .swiper-slide-active .testmonial-card  {
  /* background-color: red; */
  transform: scale(1.3);
  /* width: 360px !important; */
}


.custom-fill  svg{

  fill: #1D337A;

}
.custom-fill-active svg{

  fill: white;

}
.custom-fill-transparent svg{

  fill: white;

}
.custom-fill-transparent:hover svg{

  fill: #1D337A;

}
