
@font-face {
  font-family: 'ZonaPro';
  src : local('ZonaPro'),url("./fonts/ZonaPro-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: 'ZonaPro';
  src : local('ZonaPro'),url("./fonts/ZonaPro-SemiBold.ttf");
  font-weight: medium;
}
/* @font-face {
  font-family: 'ZonaPro';
  src : local('ZonaPro'),url("./fonts/ZonaPro-ExtraLight.otf");
  font-weight: lighter;
} */
@font-face {
  font-family: 'ZonaPro';
  src : local('ZonaPro'),url("./fonts/ZonaPro-Regular.ttf");
  
}



body, html {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8F7;
  font-family: "ZonaPro";
  /* font-family: 'Inter', sans-serif; */


}

.inter {
  font-family: 'Inter', sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}


/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(63, 164, 198, 0.603);
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1D337A;
  /* background: #a0e3e87c; */
  height: 20px;
  border-radius: 2px;
}

